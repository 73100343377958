import { AppBar, Toolbar, Typography } from "@mui/material";
import { IconButton } from "gatsby-theme-material-ui";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";

interface Props {
  backUrl: string;
}

const OrderAppBar = ({ backUrl }: Props) => {
  return (
    <>
      <AppBar>
        <Toolbar
          sx={{
            justifyContent: "space-between",
            alignItems: "stretch",
          }}
        >
          <IconButton
            to={backUrl}
            sx={{
              visibility: backUrl === "" ? "hidden" : "visible",
            }}
          >
            <ArrowBackIcon color="secondary" />
          </IconButton>

          <Typography
            variant="subtitle2"
            sx={{ fontWeight: "bold", alignSelf: "center", color: "white" }}
          >
            Objednávka
          </Typography>
          <IconButton to={"/"}>
            <CloseIcon color="secondary" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default OrderAppBar;
