import * as React from "react";
import { Box, Stack, Typography, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const IosA2HSNavigation = (props: { onDiscard: () => void }) => {
  return (
    <Box
      sx={{
        background: "#FFF",
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          px: 2,
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Typography variant="h6">Jak přidat na plochu na iOS</Typography>
          <Stack direction="row">
            <ol>
              <li>
                <Typography variant="body2">
                  1. Zobrazte si dolní lištu scrolnutím nahoru
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  2. Na dolní liště klikněte na tlačítko sdílet
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  3. V následujícím okně najděte možnost "přidat na plochu"
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  4. Klikněte na tlačítko přidat
                </Typography>
              </li>
            </ol>

            <IconButton onClick={() => props.onDiscard()}>
              <ClearIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default IosA2HSNavigation;
