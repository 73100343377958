/* tslint:disable */
/* eslint-disable */
/**
 * Kalkulacka API
 * Kalkulacka web api
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: lolita.havrychko@synetech.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetCategory200ResponseValue,
  GetCategoryId200ResponseValue,
} from '../models/index';
import {
    GetCategory200ResponseValueFromJSON,
    GetCategory200ResponseValueToJSON,
    GetCategoryId200ResponseValueFromJSON,
    GetCategoryId200ResponseValueToJSON,
} from '../models/index';

export interface GetCategoryIdRequest {
    id: string;
}

/**
 * 
 */
export class CategoryApi extends runtime.BaseAPI {

    /**
     * Your GET endpoint
     */
    async getCategoryRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: GetCategory200ResponseValue; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/category`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, GetCategory200ResponseValueFromJSON));
    }

    /**
     * Your GET endpoint
     */
    async getCategory(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: GetCategory200ResponseValue; }> {
        const response = await this.getCategoryRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getCategoryIdRaw(requestParameters: GetCategoryIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: GetCategoryId200ResponseValue; }>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getCategoryId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/category/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, GetCategoryId200ResponseValueFromJSON));
    }

    /**
     */
    async getCategoryId(requestParameters: GetCategoryIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: GetCategoryId200ResponseValue; }> {
        const response = await this.getCategoryIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
