import * as React from "react";
import "@fontsource/roboto/latin-ext-400.css";
import "@fontsource/roboto/latin-ext-500.css";
import "@fontsource/roboto/latin-ext-700.css";
import Layout from "./src/components/Layout";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import csLocale from "date-fns/locale/cs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CartContextProvider } from "./src/context/CartContext";
import { PhoneFunctionsProvider } from "./src/context/PhoneFunctionsContext";
import OrderLayout from "./src/components/Layout/OrderLayout";
import { navigate } from "gatsby";
import { QueryParamProvider } from "use-query-params";
import { CategoryContextProvider } from "./src/context/CategoryContext";
import * as Yup from "yup";
import { Formik } from "formik";
import { globalHistory, Location } from "@reach/router";
import { APIContextProvider } from "./src/context/APIContext";
import ReactQueryClientProvider from "./src/context/ReactQueryContext";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

let cachedReachHistory = undefined;
let cachedAdaptedReachHistory = undefined;

/**
 * Adapt @reach/router history to work with use-query-params' history
 * interface.
 *
 * This version uses Gatsby's `navigate` and relative URLs to avoid
 * 404s.
 *
 * @param history globalHistory from @reach/router
 */
export function adaptReachHistory(history) {
  if (history === cachedReachHistory && cachedAdaptedReachHistory != null) {
    return cachedAdaptedReachHistory;
  }

  const adaptedReachHistory = {
    async push(location) {
      // console.log("push state");
      await navigate(location.search || "?", { replace: false });
    },
    async replace(location) {
      await navigate(location.search || "?", { replace: true });
    },
    get location() {
      return history.location;
    },
  };

  cachedReachHistory = history;
  cachedAdaptedReachHistory = adaptedReachHistory;

  return adaptedReachHistory;
}

const phoneRegExp = new RegExp(/(\+\d{3})?\d{9}/, "i");
const SignupSchema = Yup.object().shape({
  fullName: Yup.string().required("Povinné pole"),
  tel: Yup.string()
    .required("Povinné pole")
    .matches(phoneRegExp, "Uveďte prosím správný formát tel.čisla."),
  email: Yup.string()
    .required("Povinné pole")
    .email("Uveďte prosím správný formát e-mailu."),
  transport: Yup.string().required("Povinné pole"),
  address: Yup.string().when("transport", {
    is: (transport) => transport === "Potřebuji dopravu",
    then: Yup.string().required("Povinné pole"),
  }),
  dateFrom: Yup.date()
    .required("Povinné pole")
    .typeError("Neplatný formát data")
    .min(new Date(), "Datum musí být pozdější než dnes"),
  dateTo: Yup.date()
    .min(
      Yup.ref("dateFrom"),
      "Datum vypršení platnosti musí být delší než datum vydání"
    )
    .required("Povinné pole")
    .typeError("Neplatný formát data"),
});

export const wrapRootElement = ({ element }) => {
  return (
    <APIContextProvider>
      <ReactQueryClientProvider>
        <Location>
          {({ location }) => (
            <QueryParamProvider
              location={location}
              history={adaptReachHistory(globalHistory)}
            >
              <PhoneFunctionsProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={csLocale}
                >
                  <CategoryContextProvider>
                    <CartContextProvider>
                      <Formik
                        initialValues={{
                          fullName: "",
                          tel: "",
                          email: "",
                          transport: "Potřebuji dopravu",
                          address: "",
                          dateFrom: "",
                          dateTo: "",
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validationSchema={SignupSchema}
                        onSubmit={() => {
                          navigate("/order-sum");
                        }}
                      >
                        {element}
                      </Formik>
                    </CartContextProvider>
                  </CategoryContextProvider>
                </LocalizationProvider>
              </PhoneFunctionsProvider>
            </QueryParamProvider>
          )}
        </Location>
        <ReactQueryDevtools initialIsOpen={false} />
      </ReactQueryClientProvider>
    </APIContextProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  if (props.location.pathname.includes("thank-you")) return element;
  if (props.location.pathname.includes("order-sum"))
    return <OrderLayout backUrl="/contact">{element}</OrderLayout>;
  if (props.location.pathname.includes("order"))
    return <OrderLayout backUrl="">{element}</OrderLayout>;
  if (props.location.pathname.includes("contact"))
    return <OrderLayout backUrl="/order">{element}</OrderLayout>;
  return <Layout {...props}>{element}</Layout>;
};
