import * as React from "react";
import { createContext, ReactNode, useState } from "react";

interface ICartContext {
  addItemToCart: (item: Item) => void;
  changeNote: (index: number, newNote: string) => void;
  changeCount: (index: number, newCount: number) => void;
  deleteItem: (index: number) => void;
  clearCart: () => void;
  cartSum: number;
  isSomeItems: boolean;
  cartContent: Item[];
}

interface Item {
  name: string;
  pricePerOne: number;
  count: number;
  note?: string;
}

const CartContext = createContext<ICartContext>({
  addItemToCart: () => {
    return;
  },
  changeNote: () => {
    return;
  },
  changeCount: () => {
    return;
  },
  deleteItem: () => {
    return;
  },
  clearCart: () => {
    return;
  },
  cartSum: 0,
  isSomeItems: false,
  cartContent: [],
});

export default CartContext;

export function CartContextProvider({ children }: { children: ReactNode }) {
  const [cartContent, setCartContent] = useState<Item[]>([]);

  return (
    <CartContext.Provider
      value={{
        addItemToCart(item) {
          if (item.count < 1) return;
          setCartContent((oldContent) => {
            const v = oldContent.find((elem) => elem.name === item.name);
            if (v == undefined) return [...oldContent, item];
            return [
              ...oldContent.slice(0, oldContent.indexOf(v)),
              { ...v, count: v.count + item.count },
              ...oldContent.slice(oldContent.indexOf(v) + 1),
            ];
          });
        },
        changeNote(index, newNote) {
          setCartContent((oldContent) => {
            return [
              ...oldContent.slice(0, index),
              { ...oldContent[index], note: newNote },
              ...oldContent.slice(index + 1),
            ];
          });
        },
        changeCount(index, newCount) {
          if (newCount < 1) return;
          setCartContent((oldContent) => {
            return [
              ...oldContent.slice(0, index),
              { ...oldContent[index], count: newCount },
              ...oldContent.slice(index + 1),
            ];
          });
        },
        deleteItem(index) {
          setCartContent((oldContent) => {
            return oldContent.filter((_, i) => i !== index);
          });
        },
        clearCart() {
          setCartContent([]);
        },
        cartSum: cartContent.reduce(
          (total, item) => item.count * item.pricePerOne + total,
          0
        ),
        isSomeItems: cartContent.length > 0,
        cartContent: cartContent,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
