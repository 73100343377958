import * as React from "react";
import { Collapse, MenuItem, MenuList } from "@mui/material";
import { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Category,
  CategoryWithoutSubcategories,
  CategoryWithSubcategories,
} from "../../types";

interface INestedMenuItemProps {
  item: Category;
  indent: number;
  onCategorySelected: (item: CategoryWithoutSubcategories) => void;
}

interface ISingleLevel {
  item: CategoryWithoutSubcategories;
  indent: number;
  onCategorySelected: (item: CategoryWithoutSubcategories) => void;
}

interface IMultiLevel {
  item: CategoryWithSubcategories;
  indent: number;
  onCategorySelected: (item: CategoryWithoutSubcategories) => void;
}

const NestedMenuItem = ({
  item,
  indent,
  onCategorySelected,
}: INestedMenuItemProps) => {
  return item.subcategories == undefined ? (
    <SingleLevel
      item={item}
      indent={indent}
      onCategorySelected={onCategorySelected}
    />
  ) : (
    <MultiLevel
      item={item}
      indent={indent}
      onCategorySelected={onCategorySelected}
    />
  );
};

export default NestedMenuItem;

const SingleLevel = ({ indent, item, onCategorySelected }: ISingleLevel) => {
  return (
    <MenuItem
      sx={{
        mr: indent,
        pl: indent + 2,
        py: indent == 0 ? "auto" : 1.5,
      }}
      onClick={() => onCategorySelected(item)}
    >
      {item.name}
    </MenuItem>
  );
};

const MultiLevel = ({ item, indent, onCategorySelected }: IMultiLevel) => {
  const { subcategories: children } = item;
  const [open, setOpen] = useState(false);

  return (
    <>
      <MenuItem
        onClick={() => setOpen((oldIsOpen) => !oldIsOpen)}
        sx={{
          pl: indent + 2,
          py: indent == 0 ? "auto" : 1.5,
        }}
      >
        {item.name}
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </MenuItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{
          background: indent == 0 ? "background.default" : "#1A1917",
          width: "100%",
        }}
      >
        <MenuList>
          {children.map((child: Category) => (
            <NestedMenuItem
              key={child.name}
              item={child}
              indent={indent + 1}
              onCategorySelected={(item) => {
                setOpen(false);
                onCategorySelected(item);
              }}
            />
          ))}
        </MenuList>
      </Collapse>
    </>
  );
};
