import * as React from "react";
import { SvgIcon } from "@mui/material";

const ThumbUpIcon = (props): JSX.Element => {
  return (
    <SvgIcon
      width="32"
      height="32"
      {...props}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2C0 .9.9 0 2 0h28a2 2 0 0 1 2 2v28a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Z"
        fill="#FFE35B"
      />
      <g clipPath="url(#a)">
        <path
          d="M19.52 12.53h1.76c.97 0 1.71.44 2.26 1.25.37.52.5 1.13.45 1.77a3.3 3.3 0 0 1-.47 1.47.14.14 0 0 0-.02.1c.17.76.07 1.5-.29 2.2a.2.2 0 0 0-.02.1c.1.83-.07 1.59-.55 2.27a.28.28 0 0 0-.04.14c-.02.52-.1 1.02-.32 1.49-.27.58-.7 1-1.24 1.3-.48.25-1 .37-1.54.37h-1.77a7.7 7.7 0 0 1-2.16-.36c-.75-.23-1.48-.49-2.22-.73-.28-.1-.57-.18-.85-.26a.8.8 0 0 0-.22-.02h-2.9c-.51 0-.9-.23-1.19-.68a1.3 1.3 0 0 1-.19-.7v-6.92a1.37 1.37 0 0 1 1.39-1.4h2.69c.09 0 .16-.02.22-.1.26-.28.53-.57.77-.88.38-.46.73-.94 1.09-1.4.15-.2.3-.38.46-.56.22-.23.35-.5.42-.8.1-.41.17-.83.28-1.23.07-.3.15-.58.27-.85.16-.39.4-.71.77-.93.18-.1.38-.16.58-.17.59 0 1.15.1 1.67.38.58.3.96.78 1.16 1.41a5.2 5.2 0 0 1-.2 3.6l-.04.1-.01.04ZM12 22.22h.13c.2.01.4.01.58.04.48.08.94.26 1.4.43.75.28 1.52.54 2.31.71.46.1.91.19 1.38.2h1.58c.2-.01.41-.04.61-.08.73-.16 1.13-.62 1.23-1.39a3.3 3.3 0 0 0-.02-.85c0-.03 0-.1.03-.11.24-.16.4-.38.5-.65.18-.46.2-.92-.02-1.37-.03-.05-.02-.08.02-.12.15-.15.28-.33.36-.53.24-.58.16-1.14-.13-1.68l-.09-.13c.26-.03.4-.2.5-.4l.15-.35c.11-.31.17-.63.09-.96-.12-.5-.67-1.06-1.32-1.06-1.17.02-2.34 0-3.5 0h-.08c-.05.01-.06-.02-.06-.06l.02-.2c.05-.4.19-.77.34-1.14.18-.42.37-.83.5-1.27.22-.7.18-1.4-.05-2.08a1 1 0 0 0-.54-.6 2.22 2.22 0 0 0-.83-.18c-.08 0-.13.03-.17.09-.14.19-.22.4-.27.62l-.37 1.57c-.1.41-.28.8-.56 1.11-.46.53-.89 1.09-1.3 1.65a8.9 8.9 0 0 1-1.53 1.67c-.23.18-.5.23-.79.2-.08 0-.1.02-.1.1V22.23Z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(8 7)" d="M0 0h16v18H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
export default ThumbUpIcon;
