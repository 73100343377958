import { createTheme } from "@mui/material";

const theme = createTheme({
  // spacing: [ 0, 2, 0.5x MUI default (8) ],
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          background: "#262626",
          color: "#FFF",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "a:not([style]):not([class])": {
          color: "inherit",
        },
        a: {
          textDecoration: "none",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root({ theme }) {
          return {
            borderRadius: theme.spacing(0.5),
          };
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        variant: "contained",
      },
      styleOverrides: {
        root({ theme }) {
          return {
            boxShadow: "none",
            borderRadius: theme.spacing(0.5),
            textTransform: "none",
          };
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorTop: {
          top: "55px",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          background: "#1A1917",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          background: "#1A1917",
          color: "#FFF",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          width: "358px",
        },
      },
    },
    MuiCardContent: {
      "&:last-child:": {
        paddingBottom: 0,
      },
      root: {
        "&:last-child:": {
          paddingBottom: 0,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorSecondary: {
          color: "#FFF",
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiTypography: {
      styleOverrides: {
        root({ theme }) {
          return {
            color: theme.palette.grey["500"],
          };
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#FFDE44",
    },
    background: {
      light: "#FFFFFF",
      default: "#F0F0F0",
    },
    text: {
      light: "#262626",
      primary: "#1A1917",
    },
    success: {
      main: "#1A1917",
    },
    active: {
      main: "#808080",
    },
    error: {
      main: "#F44336",
    },
    grey: {
      50: "#F0F0F0",
      100: "#CCCCCC",
      200: "#808080",
      300: "#4D4D4D",
      400: "#262626",
      500: "#1A1917",
    },
  },
});

export default theme;
