import * as React from "react";
import { ReactNode } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import OrderAppBar from "../Bars/OrderAppBar";
import MenuAppBar from "../Bars/MenuAppBar";

interface Props {
  backUrl: string;
  children: ReactNode;
}

export default function OrderLayout({ backUrl, children }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isMobile && <OrderAppBar backUrl={backUrl} />}
      {!isMobile && <MenuAppBar />}
      <main>
        {!isMobile && (
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              pt: 4,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
              }}
            >
              Objednávka
            </Typography>
          </Box>
        )}
        {children}
      </main>
    </>
  );
}
