import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useContext } from "react";
import APIContext from "../context/APIContext";
import { Category } from "../types";

type Item = {
  name: string;
  price: number;
  imagePath: string;
};

export const useProducts = (id: string) => {
  const { categoryApi } = useContext(APIContext);

  return useQuery(["item", id], async () => {
    const result = await categoryApi.getCategoryId({
      id,
    });
    return Object.entries(result)
      .filter(
        ([_, item]) =>
          item.name != null && item.price != null && item.mainPhotoPath != null
      )
      .map(
        ([_, item]): Item => ({
          name: item.name as string,
          price: item.price as number,
          imagePath: item.mainPhotoPath as string,
        })
      );
  });
};

export const useCategories = (): UseQueryResult<Category[]> => {
  const { categoryApi } = useContext(APIContext);

  return useQuery(["categories"], async () => {
    const result = await categoryApi.getCategory();
    return Object.entries(result).map(([index, item]) =>
      item.subcategories != null
        ? {
            name: item.name,
            id: index,
            subcategories: Object.keys(item.subcategories).map((key) => ({
              // @ts-ignore
              name: item.subcategories[key].name,
              // @ts-ignore
              url: item.subcategories[key].nameUrl,
              id: key,
            })),
          }
        : {
            name: item.name,
            url: item.nameUrl,
            id: index,
          }
    );
  });
};
