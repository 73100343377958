import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Drawer,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import NestedMenuItem from "./NestedMenuItem";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Button as GatsbyButton } from "gatsby-theme-material-ui";
import { useTheme } from "@mui/material/styles";
import CategoryContext from "../../context/CategoryContext";
import { useCategories } from "../../hooks/queryHooks";
import { Category, CategoryWithoutSubcategories } from "../../types";

const menuDownText = "Všechny kategorie";

interface IMobileMenu {
  curCatName: string | null | undefined;
  apiData: Array<Category>;
  onCategorySelect: (category: CategoryWithoutSubcategories) => void;
}

interface IDesktopMenu {
  item: Category;
  onCategorySelect: (category: CategoryWithoutSubcategories) => void;
}

const CategoryMenu = () => {
  const theme = useTheme();
  const { currentCategory, changeCategory, parameterCategoryName } =
    useContext(CategoryContext);
  const { data, isLoading, isSuccess } = useCategories();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const allSelectableCategories = useMemo(
    () =>
      data?.flatMap((category) =>
        category.subcategories != undefined ? category.subcategories : category
      ) ?? [],
    [data]
  );

  useEffect(() => {
    if (!isSuccess || isLoading) {
      return;
    }

    if (parameterCategoryName != null && parameterCategoryName.length > 0) {
      loadCategoryFromUrl();
    } else if (!currentCategory || currentCategory.name === "") {
      // Category is not specified in URL.
      // Case where we load the page for the first time, so even category object is not specified.
      loadDefaultCategory();
    }
  }, [data]);

  const loadCategoryFromUrl = () => {
    const foundCat = allSelectableCategories.find(
      (category) => category.name == parameterCategoryName
    );
    if (foundCat == null) return;

    changeCategory(foundCat);
  };

  const loadDefaultCategory = () => {
    const defaultCategory = allSelectableCategories[0];
    changeCategory({
      name: defaultCategory?.name,
      url: defaultCategory?.url,
      id: defaultCategory.id,
    });
  };

  return (
    <>
      {isLoading && (
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="body2">Vyčkejte prosím</Typography>
        </Stack>
      )}
      {isMobile ? (
        <>
          {data && (
            <MobileMenu
              curCatName={parameterCategoryName}
              apiData={data}
              onCategorySelect={(item) => {
                changeCategory(item);
              }}
            />
          )}
        </>
      ) : (
        <Stack direction="row" alignItems="center" justifyContent="center">
          {data &&
            data.map((item) => (
              <DesktopMenuItem
                item={item}
                key={item.name}
                onCategorySelect={(item) => {
                  changeCategory(item);
                }}
              />
            ))}
        </Stack>
      )}
    </>
  );
};

const MobileMenu = ({ curCatName, apiData, onCategorySelect }: IMobileMenu) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <>
      <Button
        variant="text"
        onClick={() => setIsMenuOpen(true)}
        endIcon={<KeyboardArrowDown color="primary" />}
        sx={{
          color: "#FFF",
        }}
      >
        {isMenuOpen ? menuDownText : curCatName}
      </Button>
      <Drawer
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        anchor="top"
      >
        <MenuList
          sx={{
            background: "#1A1917",
            color: "white",
          }}
        >
          {apiData.map((item) => (
            <NestedMenuItem
              item={item}
              indent={0}
              key={item.name}
              onCategorySelected={(item) => {
                setIsMenuOpen(false);
                onCategorySelect(item);
              }}
            />
          ))}
        </MenuList>
      </Drawer>
    </>
  );
};

const DesktopMenuItem = ({ item, onCategorySelect }: IDesktopMenu) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = anchorEl !== null;
  let DesktopButton: typeof GatsbyButton | typeof Button = GatsbyButton;

  return (
    <>
      {item.subcategories ? (
        <>
          <Button
            variant="text"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            endIcon={
              isOpen ? (
                <KeyboardArrowUp color="primary" />
              ) : (
                <KeyboardArrowDown color="primary" />
              )
            }
            sx={{
              color: "#FFF",
            }}
          >
            {item.name}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={isOpen}
            onClose={() => setAnchorEl(null)}
          >
            {item.subcategories.map((child: CategoryWithoutSubcategories) => (
              <MenuItem
                key={child.name}
                onClick={() => {
                  setAnchorEl(null);
                  onCategorySelect(child);
                }}
              >
                {child.name}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <DesktopButton
          to="/"
          variant="text"
          sx={{
            color: "#FFF",
          }}
          onClick={() => {
            setAnchorEl(null);
            onCategorySelect(item);
          }}
        >
          {item.name}
        </DesktopButton>
      )}
    </>
  );
};

export default CategoryMenu;
