import * as React from "react";
import { Button, Stack, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const AddToHomeScreen = (props: {
  onDiscard: () => void;
  onAccept: () => void;
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: "100%",
        height: "50px",
        px: 2,
        background: "#FFF",
      }}
    >
      <Typography variant="caption">PronajmiSiTo Kalkulačka</Typography>
      <Stack direction="row">
        <Button
          onClick={props.onAccept}
          sx={{
            height: "34px",
            px: 1.5,
            my: "auto",
          }}
        >
          Přidat na plochu
        </Button>
        <ClearIcon
          onClick={props.onDiscard}
          sx={{
            my: "auto",
            ml: 2,
            color: "grey.300",
          }}
        />
      </Stack>
    </Stack>
  );
};

export default AddToHomeScreen;
