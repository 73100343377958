import * as React from "react";
import { createContext, ReactNode, useEffect, useState } from "react";
import { CategoryWithoutSubcategories } from "../types";
import { StringParam, useQueryParam } from "use-query-params";

interface ICategoryContext {
  changeCategory: (newCategory: CategoryWithoutSubcategories) => void;
  currentCategory: CategoryWithoutSubcategories | null;
  parameterCategoryName: string | null | undefined;
}

const CategoryContext = createContext<ICategoryContext>({
  changeCategory: () => {
    throw new Error("Unexpected call of undefined method");
  },
  currentCategory: null,
  parameterCategoryName: null,
});

export default CategoryContext;

export function CategoryContextProvider({ children }: { children: ReactNode }) {
  const [currentCategory, setCurrentCategory] =
    useState<CategoryWithoutSubcategories>({
      name: "",
      url: "",
      id: "0",
    });
  const [parameterCategoryName, setParameterCategory] = useQueryParam(
    "category",
    StringParam
  );

  useEffect(() => {
    setParameterCategory(currentCategory.name);
  }, [currentCategory]);

  return (
    <CategoryContext.Provider
      value={{
        changeCategory(newCategory) {
          setCurrentCategory(newCategory);
        },
        currentCategory,
        parameterCategoryName,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
}
