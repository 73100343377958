/* tslint:disable */
/* eslint-disable */
/**
 * Kalkulacka API
 * Kalkulacka web api
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: lolita.havrychko@synetech.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetCategory200ResponseValueSubcategoriesValue } from './GetCategory200ResponseValueSubcategoriesValue';
import {
    GetCategory200ResponseValueSubcategoriesValueFromJSON,
    GetCategory200ResponseValueSubcategoriesValueFromJSONTyped,
    GetCategory200ResponseValueSubcategoriesValueToJSON,
} from './GetCategory200ResponseValueSubcategoriesValue';

/**
 * 
 * @export
 * @interface GetCategory200ResponseValue
 */
export interface GetCategory200ResponseValue {
    /**
     * 
     * @type {string}
     * @memberof GetCategory200ResponseValue
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCategory200ResponseValue
     */
    nameUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCategory200ResponseValue
     */
    order?: number;
    /**
     * 
     * @type {{ [key: string]: GetCategory200ResponseValueSubcategoriesValue; }}
     * @memberof GetCategory200ResponseValue
     */
    subcategories?: { [key: string]: GetCategory200ResponseValueSubcategoriesValue; };
}

/**
 * Check if a given object implements the GetCategory200ResponseValue interface.
 */
export function instanceOfGetCategory200ResponseValue(value: object): boolean {
    return true;
}

export function GetCategory200ResponseValueFromJSON(json: any): GetCategory200ResponseValue {
    return GetCategory200ResponseValueFromJSONTyped(json, false);
}

export function GetCategory200ResponseValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCategory200ResponseValue {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'nameUrl': json['name-url'] == null ? undefined : json['name-url'],
        'order': json['order'] == null ? undefined : json['order'],
        'subcategories': json['subcategories'] == null ? undefined : (mapValues(json['subcategories'], GetCategory200ResponseValueSubcategoriesValueFromJSON)),
    };
}

export function GetCategory200ResponseValueToJSON(value?: GetCategory200ResponseValue | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'name-url': value['nameUrl'],
        'order': value['order'],
        'subcategories': value['subcategories'] == null ? undefined : (mapValues(value['subcategories'], GetCategory200ResponseValueSubcategoriesValueToJSON)),
    };
}

