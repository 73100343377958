import * as React from "react";
import { createContext, ReactNode } from "react";
import { CategoryApi } from "../../apis";

interface IAPIContext {
  categoryApi: CategoryApi;
}

const APIContext = createContext<IAPIContext>({
  categoryApi: null,
});

export default APIContext;

export function APIContextProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  return (
    <APIContext.Provider
      value={{
        categoryApi: new CategoryApi(),
      }}
    >
      {children}
    </APIContext.Provider>
  );
}
