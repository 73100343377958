import React, { ReactNode, useEffect, useState, createContext } from "react";

export interface IPhoneFunctionsProvider {
  acceptAddToDesktop: () => void;
  hideAddToDesktopBar: () => void;
  iosA2HS: boolean;
  pwaAddEvent: Event | string | undefined;
}

const PhoneFunctionsContext = createContext<IPhoneFunctionsProvider>({
  acceptAddToDesktop: () => {
    throw new Error("Unexpected call of undefined method");
  },
  hideAddToDesktopBar: () => {
    throw new Error("Unexpected call of undefined method");
  },
  iosA2HS: null,
  pwaAddEvent: null,
});

interface Props {
  children: ReactNode;
}

const isIos = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export function PhoneFunctionsProvider({ children }: Props): JSX.Element {
  const [iosA2HS, setIosA2HS] = useState<boolean>(false);
  const [pwaAddEvent, setPwaAddEvent] = useState<Event | undefined | string>(
    undefined
  );
  const [iOSCanInstall, setIOSCanInstall] = useState(false);
  const [iOSIsInstalled, setIOSIsInstalled] = useState(false);

  useEffect(() => {
    setIOSCanInstall(() => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    });

    setIOSIsInstalled(
      "standalone" in window.navigator && window.navigator.standalone
    );

    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Update UI to notify the user they can add to home screen
      setPwaAddEvent(e);
    });
  }, []);

  useEffect(() => {
    if (isIos() && iOSCanInstall && !iOSIsInstalled) {
      setPwaAddEvent("iosHelper");
    }
  }, [iOSCanInstall, iOSIsInstalled]);

  const hideAddToDesktopBar = () => {
    setPwaAddEvent(undefined);
  };

  const acceptAddToDesktop = () => {
    if (!isIos()) {
      pwaAddEvent.prompt();
      setPwaAddEvent(undefined);
    } else {
      setIosA2HS(true);
    }
  };

  return (
    <PhoneFunctionsContext.Provider
      value={{
        acceptAddToDesktop,
        hideAddToDesktopBar,
        iosA2HS,
        pwaAddEvent,
      }}
    >
      {children}
    </PhoneFunctionsContext.Provider>
  );
}

export default PhoneFunctionsContext;
