import * as React from "react";
import { ReactNode } from "react";
import MenuAppBar from "../Bars/MenuAppBar";

interface Props {
  children: ReactNode;
}

export default function Layout({ children }: Props) {
  return (
    <>
      <MenuAppBar />
      <main>{children}</main>
    </>
  );
}
