/* tslint:disable */
/* eslint-disable */
/**
 * Kalkulacka API
 * Kalkulacka web api
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: lolita.havrychko@synetech.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCategoryId200ResponseValue
 */
export interface GetCategoryId200ResponseValue {
    /**
     * 
     * @type {string}
     * @memberof GetCategoryId200ResponseValue
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCategoryId200ResponseValue
     */
    nameUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCategoryId200ResponseValue
     */
    perex?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCategoryId200ResponseValue
     */
    textInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCategoryId200ResponseValue
     */
    priceInfo?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCategoryId200ResponseValue
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCategoryId200ResponseValue
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCategoryId200ResponseValue
     */
    mainPhotoPath?: string;
}

/**
 * Check if a given object implements the GetCategoryId200ResponseValue interface.
 */
export function instanceOfGetCategoryId200ResponseValue(value: object): boolean {
    return true;
}

export function GetCategoryId200ResponseValueFromJSON(json: any): GetCategoryId200ResponseValue {
    return GetCategoryId200ResponseValueFromJSONTyped(json, false);
}

export function GetCategoryId200ResponseValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCategoryId200ResponseValue {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'nameUrl': json['name-url'] == null ? undefined : json['name-url'],
        'perex': json['perex'] == null ? undefined : json['perex'],
        'textInfo': json['text_info'] == null ? undefined : json['text_info'],
        'priceInfo': json['price_info'] == null ? undefined : json['price_info'],
        'price': json['price'] == null ? undefined : json['price'],
        'order': json['order'] == null ? undefined : json['order'],
        'mainPhotoPath': json['main_photo_path'] == null ? undefined : json['main_photo_path'],
    };
}

export function GetCategoryId200ResponseValueToJSON(value?: GetCategoryId200ResponseValue | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'name-url': value['nameUrl'],
        'perex': value['perex'],
        'text_info': value['textInfo'],
        'price_info': value['priceInfo'],
        'price': value['price'],
        'order': value['order'],
        'main_photo_path': value['mainPhotoPath'],
    };
}

