import * as React from "react";
import { useContext } from "react";
import { AppBar, Container, Toolbar } from "@mui/material";
import { IconButton } from "gatsby-theme-material-ui";
import AddToHomeScreen from "../AddToHomeScreen";
import IosA2HSNavigation from "../IosA2HSNavigation";
import PhoneFunctionsContext from "../../context/PhoneFunctionsContext";
import CategoryMenu from "../Menu/CategoryMenu";
import ThumbUpIcon from "../icons/ThumbUpIcon";
import CategoryContext from "../../context/CategoryContext";

const MenuAppBar = () => {
  // todo typescript
  const { acceptAddToDesktop, hideAddToDesktopBar, pwaAddEvent, iosA2HS } =
    useContext(PhoneFunctionsContext);
  const { currentCategory } = useContext(CategoryContext);

  return (
    <>
      <AppBar>
        <Toolbar
          sx={{
            width: "100%",
            justifyContent: "space-between",
            "::after": {
              content: '""',
              flex: 1,
            },
          }}
          component={Container}
        >
          <div
            style={{
              marginRight: "auto",
              padding: 0,
              justifyContent: "flex-start",
              flex: 1,
            }}
          >
            <IconButton
              sx={{
                padding: 0,
              }}
              to={`/?category=${currentCategory.name}`}
            >
              <ThumbUpIcon />
            </IconButton>
          </div>
          <CategoryMenu />
        </Toolbar>
        {pwaAddEvent && !iosA2HS && (
          <AddToHomeScreen
            onDiscard={hideAddToDesktopBar}
            onAccept={acceptAddToDesktop}
          />
        )}
        {pwaAddEvent && iosA2HS && (
          <IosA2HSNavigation onDiscard={hideAddToDesktopBar} />
        )}
      </AppBar>
      <Toolbar
        sx={{
          mb: pwaAddEvent ? (iosA2HS ? "180px" : "50px") : "",
        }}
      />
    </>
  );
};

export default MenuAppBar;
